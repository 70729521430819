import { FormService } from './form-service';
import { Injectable } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { HelpersService } from '../../../../../services/helpers/helpers.service';
import { DebtorDialogComponent } from '../../dialogs/debtor-dialog/debtor-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { InitStepService } from "../init-step.service";
import { GroupTypeEnum } from "../../models/common/group-type-enum";
import { DebtorStepData } from "../../models/debtor/debtor-step-data";
import { getEmptyRepresentativeData, RepresentativeData } from "../../models/common/representative-data";
import { DebtorData, getEmptyDebtorData } from "../../models/debtor/debtor-data";
import { FormErrorSnackbarComponent } from "../../../../../snackbars/form-error-snackbar/form-error-snackbar.component";
import { MatSnackBar } from "@angular/material/snack-bar";

@Injectable({
    providedIn: 'root'
})
export class DebtorFormService extends FormService {
    debtorStepData: DebtorStepData;
    form: FormGroup;

    isAddressEditable = true;

    // root controls
    get groupTypeFormControl(): FormControl {
        return this.form?.get('groupTypeFormControl') as FormControl;
    };
    get debtorFormGroup(): FormGroup {
        return this.form.get('debtorFormGroup') as FormGroup;
    };

    get representativeFormGroup(): FormGroup {
        return this.form.get('representativeFormGroup') as FormGroup;
    };

    // debtor
    get taxNumberFormControl() {
        return this.debtorFormGroup.get('taxNumberFormControl') as FormControl;
    }

    get registrationNumberFormControl() {
        return this.debtorFormGroup.get('registrationNumberFormControl') as FormControl;
    }

    get nameFormControl() {
        return this.debtorFormGroup.get('nameFormControl') as FormControl;
    }

    get countryFormControl() {
        return this.debtorFormGroup.get('countryFormControl') as FormControl;
    }

    get postcodeFormControl() {
        return this.debtorFormGroup.get('postcodeFormControl') as FormControl;
    }

    get settlementFormControl() {
        return this.debtorFormGroup.get('settlementFormControl') as FormControl;
    }

    get streetFormControl() {
        return this.debtorFormGroup.get('streetFormControl') as FormControl;
    }

    // representative
    get repNameFormControl() {
        return this.representativeFormGroup.get('repNameFormControl') as FormControl;
    }

    get repTaxNumberFormControl() {
        return this.representativeFormGroup.get('repTaxNumberFormControl') as FormControl;
    }

    get repTypeFormControl() {
        return this.representativeFormGroup.get('repTypeFormControl') as FormControl;
    }

    get repCountryFormControl() {
        return this.representativeFormGroup.get('repCountryFormControl') as FormControl;
    }

    get repPostcodeFormControl() {
        return this.representativeFormGroup.get('repPostcodeFormControl') as FormControl;
    }

    get repSettlementFormControl() {
        return this.representativeFormGroup.get('repSettlementFormControl') as FormControl;
    }

    get repStreetFormControl() {
        return this.representativeFormGroup.get('repStreetFormControl') as FormControl;
    }

    get debtorAddress() {
        return this.postcodeFormControl.value + ' '
            + this.settlementFormControl.value + ', '
            + this.streetFormControl.value;
    }

    get representativeAddress() {
        return this.repPostcodeFormControl.value + ' '
            + this.repSettlementFormControl.value + ', '
            + this.repStreetFormControl.value;
    }

    get isHouseDebtor() {
        return this.groupTypeFormControl?.value === GroupTypeEnum.HOUSE_WITH_REPRESENTATIVE_WITHOUT_TAX_NUMBER
            || this.groupTypeFormControl?.value === GroupTypeEnum.HOUSE_WITH_REPRESENTATIVE_WITH_TAX_NUMBER;
    }

    get isHouseWithRepresentativeWithTaxNumber() {
        return this.groupTypeFormControl?.value === GroupTypeEnum.HOUSE_WITH_REPRESENTATIVE_WITH_TAX_NUMBER;
    }

    get isHouseWithRepresentativeWithoutTaxNumber() {
        return this.groupTypeFormControl?.value === GroupTypeEnum.HOUSE_WITH_REPRESENTATIVE_WITHOUT_TAX_NUMBER;
    }

    get isIndividualWithoutTaxNumber() {
        return this.groupTypeFormControl?.value === GroupTypeEnum.INDIVIDUAL_WITHOUT_TAX_NUMBER;
    }

    get isOtherWithTaxNumber() {
        return this.groupTypeFormControl?.value === GroupTypeEnum.OTHER_WITH_TAX_NUMBER;
    }

    constructor(
        public helpersService: HelpersService,
        public dialog: MatDialog,
        private formBuilder: FormBuilder,
        private initStepService: InitStepService,
        private snackbar: MatSnackBar,
    ) {
        // set initial parent basic data
        super(helpersService, dialog);
        this.setText(
            'Ki tartozik? (Adós)',
            'Add meg az adósodat'
        );
        this.urlName = 'debtor';
        this.isEditable = false;

        // TODO: set szamlazzhu specific data
        //this.showSzamlazzhuIcon = this.formHelperService.isSzamlazzhu;
        //this.isDebtorEditable = !this.formHelperService.isSzamlazzhu;
        //this.isAddressEditable = !this.formHelperService.isSzamlazzhu;

        // set debtor step data
        this.debtorStepData = this.initStepService.debtorStepData;
        this.initStepService.getDebtorStepDataListener()
            .subscribe((debtorStepData: DebtorStepData) => {
                this.debtorStepData = debtorStepData;
            });
    }

    public deleteDebtor() {
        this.form.setControl('debtorFormGroup', this.getDebtorFormGroup(getEmptyDebtorData()));
        this.form.setControl('representativeFormGroup', this.getRepresentativeFormGroup(getEmptyRepresentativeData()));
    }

    public deleteRepresentative() {
        this.form.setControl('representativeFormGroup', this.getRepresentativeFormGroup(getEmptyRepresentativeData()));
    }

    // override
    buildForm() {
        this.form = this.getForm();
        this.setEditable();
    }

    // override
    setMenuTexts() {
        this.dropdownTitle = this.debtorStepData.debtor_data.debtor.name;
        this.dropdownItems = [this.debtorStepData.debtor_data.debtor.name];
    }

    // override
    openDialog() {
        return this.dialog.open(DebtorDialogComponent, {
            position: {
                top: '50px',
            },
            autoFocus: true,
            disableClose: true,
        });
    }

    // override
    async persistData() {
        this.updateDebtorStepData();
        try {
            await this.initStepService.saveDebtorStepData();
        } catch (error) {
            console.error(error);
            this.snackbar.openFromComponent(FormErrorSnackbarComponent);
        }
    }

    private getForm(): FormGroup {
        const debtorData = this.debtorStepData.debtor_data;
        const representativeData = this.debtorStepData.representative_data;

        return this.formBuilder.group({
            groupTypeFormControl: [this.debtorStepData.type ?? GroupTypeEnum.OTHER_WITH_TAX_NUMBER],
            debtorFormGroup: this.getDebtorFormGroup(debtorData),
            representativeFormGroup: this.getRepresentativeFormGroup(representativeData)
        });
    }

    private getDebtorFormGroup(debtorData: DebtorData) {
        return this.formBuilder.group({
            taxNumberFormControl: [
                debtorData.debtor.tax_number ?? '',
                [!this.isIndividualWithoutTaxNumber ? Validators.required : Validators.nullValidator]
            ],
            registrationNumberFormControl: [
                debtorData.debtor.registration_number ?? '',
                []
            ],
            nameFormControl: [
                debtorData.debtor.name ?? '',
                [Validators.required]
            ],
            countryFormControl: [
                {
                    value: 'Magyarország',
                    disabled: true,
                },
                [!this.isOtherWithTaxNumber ? Validators.required : Validators.nullValidator]],
            postcodeFormControl: [
                {
                    value: debtorData.address?.postcode ?? '',
                    disabled: !this.isAddressEditable
                },
                [!this.isOtherWithTaxNumber ? Validators.required : Validators.nullValidator]],
            settlementFormControl: [
                {
                    value: debtorData.address?.settlement ?? '',
                    disabled: !this.isAddressEditable
                },
                [!this.isOtherWithTaxNumber ? Validators.required : Validators.nullValidator]],
            streetFormControl: [
                {
                    value: debtorData.address?.street ?? '',
                    disabled: !this.isAddressEditable
                },
                [!this.isOtherWithTaxNumber ? Validators.required : Validators.nullValidator]],
        });
    }

    private getRepresentativeFormGroup(representativeData: RepresentativeData) {
        return this.formBuilder.group({
            repNameFormControl: [
                representativeData?.representative?.name ?? '',
                [this.isHouseDebtor ? Validators.required : Validators.nullValidator]
            ],
            repTaxNumberFormControl: [
                representativeData?.representative?.tax_number ?? '',
                [this.isHouseWithRepresentativeWithTaxNumber ? Validators.required : Validators.nullValidator]
            ],
            repTypeFormControl: [{
                value: 'Közös képviselő',
                disabled: true,
            }, [this.isHouseWithRepresentativeWithoutTaxNumber ? Validators.required : Validators.nullValidator]],
            repCountryFormControl: [{
                value: 'Magyarország',
                disabled: true,
            }, [this.isHouseWithRepresentativeWithoutTaxNumber ? Validators.required : Validators.nullValidator]],
            repPostcodeFormControl: [
                representativeData?.address?.postcode ?? '',
                [this.isHouseWithRepresentativeWithoutTaxNumber ? Validators.required : Validators.nullValidator]
            ],
            repSettlementFormControl: [
                representativeData?.address?.settlement ?? '',
                [this.isHouseWithRepresentativeWithoutTaxNumber ? Validators.required : Validators.nullValidator]
            ],
            repStreetFormControl: [
                representativeData?.address?.street ?? '',
                [this.isHouseWithRepresentativeWithoutTaxNumber ? Validators.required : Validators.nullValidator]
            ],
        })
    }

    private updateDebtorStepData() {
        this.debtorStepData.type = this.groupTypeFormControl.value ?? GroupTypeEnum.OTHER_WITH_TAX_NUMBER;
        this.debtorStepData.debtor_data.debtor.name = this.nameFormControl.value ?? '';
        this.debtorStepData.debtor_data.debtor.tax_number = this.taxNumberFormControl.value ?? '';
        this.debtorStepData.debtor_data.debtor.registration_number = this.registrationNumberFormControl.value ?? '';

        this.debtorStepData.debtor_data.address.country = this.countryFormControl.value ?? '';
        this.debtorStepData.debtor_data.address.postcode = this.postcodeFormControl.value ?? '';
        this.debtorStepData.debtor_data.address.settlement = this.settlementFormControl.value ?? '';
        this.debtorStepData.debtor_data.address.street = this.streetFormControl.value ?? '';

        this.debtorStepData.representative_data.representative.name = this.repNameFormControl.value ?? '';
        this.debtorStepData.representative_data.representative.tax_number = this.repTaxNumberFormControl.value ?? '';

        this.debtorStepData.representative_data.address.country = this.repCountryFormControl.value ?? '';
        this.debtorStepData.representative_data.address.postcode = this.repPostcodeFormControl.value ?? '';
        this.debtorStepData.representative_data.address.settlement = this.repSettlementFormControl.value ?? '';
        this.debtorStepData.representative_data.address.street = this.repStreetFormControl.value ?? '';
    }

    private setEditable() {
        if (this.form.invalid) {
            this.isEditable = true;
        } else if (this.countryFormControl.invalid
            || this.postcodeFormControl.invalid
            || this.settlementFormControl.invalid
            || this.streetFormControl.invalid
        ) {
            this.isAddressEditable = true;
        }
    }
}
